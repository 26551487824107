import * as React from 'react';
import { Route } from 'react-router';

import AuthenticationApp from 'bundles/authentication/components/AuthenticationApp';
import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';

const SSOCallback = loadOnRoute(
  () => import('bundles/authentication/shared/components/authentication-modal/SocialSSO/SSOCallback')
);
const ResetPage = loadOnRoute(() => import('bundles/authentication/components/reset-password/ResetPage'));
const ResetConfirmPage = loadOnRoute(() => import('bundles/authentication/components/reset-password/ResetConfirmPage'));
const DecodeMagicLinkPage = loadOnRoute(
  () => import('bundles/authentication/shared/components/authentication-modal/DecodeMagicLink')
);

const LogoutPage = loadOnRoute(() => import('bundles/authentication/components/logout/LogoutPage'));
const EmailVerificationPage = loadOnRoute(
  () => import('bundles/authentication/components/email-verification/EmailVerificationPage')
);

const StandaloneAuthPage = loadOnRoute(() => import('bundles/authentication/components/StandaloneAuthPage'));

export default (
  <Route path="/" component={AuthenticationApp}>
    <Route path="login" getComponent={StandaloneAuthPage} />
    <Route path="signup" getComponent={StandaloneAuthPage} />
    <Route path="reset" getComponent={ResetPage} />
    <Route path="reset/confirm/:token" getComponent={ResetConfirmPage} />
    <Route path="reset/create/:token" getComponent={ResetConfirmPage} />
    <Route path="magicLinks/:token" getComponent={DecodeMagicLinkPage} />

    <Route path="ssoCallback" getComponent={SSOCallback} />
    <Route path="account/logout" getComponent={LogoutPage} />
    <Route path="account/email_verify/:token" getComponent={EmailVerificationPage} />

    <Route path="ssoCallback" getComponent={SSOCallback} />

    {/* This catch-all *, status=404 route is important for SSR. */}
    <Route path="*" component={NotFound} />
  </Route>
);
